import React from "react";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/Seo";
import {Box, Button, Container, Image, T2} from "../../styles/Styles";
import Mikan from "mikanjs";

import iconCite from "../../img/icon-quote.png";
// benefits videos
import videoAlarmStadium from "../../img/alarm-stadium-bubbles.mp4";
import videoTrendsPrecTemp from "../../img/trends-prec-temp.mp4";
// benefits posters
import posterLightningStadium from "../../img/lightning-stadium.jpg";


import hero from "../../img/hero-outdoor-ind.png";
import Clients from "../../components/Homepage/Clients";


const benefits = [
    {
        webm: null,
        mp4: videoTrendsPrecTemp,
        poster: null,
        title: "準備をしましょう",
        caption: `常時変化する、雷、雪、雨、究極な温度といった、関心のある天候状況を追跡`,
        text: `開催地を究極の天候状況に合わせ準備したり、除雪といったような作業に対応する特殊スタッフ確保のタイミングを認識すること。チームとパフォーマーにこれからの天候状況を注意喚起し、トレーニングやリハーサル、パフォーマンスに影響を及ぼす可能性があることを示唆すること。`,
        image: null
    },
    {
        webm: null,
        mp4: null,
        poster: posterLightningStadium,
        title: "不必要な遅延や中止を最小限に",
        caption: `雷の方向性は、開催地に接近または遠のいていますか？`,
        text: `カスタマイズされ、開催地に特化したアラートでどんな天候状況が近づいてきているのか、それとも遠のいていっているのか、雷の方向性と動きを含め、知ることができます。`,
        image: null
    },
    {
        webm: null,
        mp4: videoAlarmStadium,
        poster: null,
        title: "設備損傷を減らす",
        caption: `荒れ模様の天候状況に応じた、開催地に特化したアラートの設定`,
        text: `不必要に大きな注意を払うことなく、電子機器を必要に応じて保護`,
        image: null
    }
];

const quote = {
    text: `もし雷が土地に落ちる可能性がある場合、皆を避難場所へ誘導するためにも、その危険性を事前に把握する必要があります。tomorrow.ioのツールはいつそのような状況が発生するのかと、<br/>過ぎ去るのかを教えてくれます。`,
    person: `ジェイソン・ストーン氏、Senior Director of Operations、Kraft Sports and Entertainment`,
    image:
        "https://cms2.climacell.co/wp-content/uploads/2019/08/jason-stone-2.png"
};

export default class OutdoorIndustry extends React.Component {

    render() {
        return (
            <Layout>
                <SEO
                    title="アウトドア | tomorrow.io"
                    image={hero}
                    pathname={this.props.location.pathname}
                />

                <Container>
                    <Box display="flex" flexWrap="wrap" pb={4}>
                        <Box
                            width={[1, 1 / 2]}
                            pr={[0, 4]}
                            pt={5}
                        >
                            <Box fontSize={17} mb={3}>
                                イベント前に正確な予報が知れたらと、願ったことはありますか？
                            </Box>

                            <T2
                                as="h1"
                                mb={4}
                                dangerouslySetInnerHTML={{__html: Mikan('悪天候によって、ショーが中止になるかもしれません。正確な予報が、一日を救います。')}}
                            />
                            <Button
                                as="a"
                                display={["block", "inline-block"]}
                                mb={2}
                                href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                            >
                                tomorrow.ioデモ取得
                            </Button>{" "}
                            <Button
                                outline="1"
                                as="a"
                                display={["block", "inline-block"]}
                                mb={2}
                                href="https://www.tomorrow.io/api-signup/"
                            >
                                APIキー取得
                            </Button>
                        </Box>
                        <Box width={[1, 1 / 2]} pt={2} px={3} textAlign="center">
                            <Image
                                maxHeight="380px"
                                src={hero}
                                alt=""
                            />
                        </Box>
                    </Box>
                </Container>

                <section className="c-section py-5">
                    <div className="container">
                        <Clients/>
                    </div>
                </section>

                <section className="c-section ">
                    <div className="container">

                        <div className="col-lg-8 mx-auto text-center px-2">
                            <h2 className="h-2-sm mb-5">
                                荒れ模様の気象状況のせいで、イベントの遅延や中止、大勢を避難させるような事象に発展するかもしれません。
                            </h2>
                            <p className="fs-lg">
                            tomorrow.ioの分単位、開催地の特化した予報によって、必要な情報を手に入れることができ、どんな天候にも対応できるよう、賢く備えることができます。人々や資産を保護すると共に、アスリートやパフォーマーさらには観客の体験を向上できます。
                            </p>
                        </div>

                        {benefits.map((item, i) => (
                            <div key={i} className="row justify-content-center py-4 py-lg-6">

                                <div
                                    className={`col-lg-4 pr-lg-4 ${
                                        i % 2 === 0 ? "order-lg-last" : ""
                                    }`}
                                >
                                    <h3 className="h-3 pt-3"
                                        dangerouslySetInnerHTML={{__html: Mikan(item.title)}}
                                    />
                                    <p className="fs-lg" dangerouslySetInnerHTML={{__html: Mikan(item.text)}}/>
                                </div>

                                <div className="col-lg-7">
                                    {!item.image && (
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <video
                                                className="embed-responsive-item shadow"
                                                poster={item.poster}
                                                width="800"
                                                height="480"
                                                controls={false}
                                                muted
                                                playsInline
                                                autoPlay
                                                loop
                                            >
                                                {item.webm && (
                                                    <source src={item.webm} type="video/webm"/>
                                                )}
                                                {item.mp4 && <source src={item.mp4} type="video/mp4"/>}
                                                } Your browser does not support the video tag
                                            </video>
                                        </div>
                                    )}
                                    {item.image && (
                                        <img
                                            className="img-fluid img-thumbnail"
                                            width="550"
                                            src={item.image}
                                            alt=""
                                        />
                                    )}
                                    <div className="text-center px-4 py-2">{item.caption}</div>
                                </div>
                            </div>
                        ))}
                        <div className="text-center pt-6">

                            <a
                                className="btn btn-lg btn-primary h-3 ls-md"
                                href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                            >
                                tomorrow.ioデモ取得
                            </a>

                        </div>
                    </div>
                </section>

                {quote && (
                    <section className="c-section pt-5 ">

                        <div className="col-lg-6 mx-auto text-center">
                            <img className="mb-3" src={iconCite} alt=""/>
                            <p
                                className="fs-lg font-italic mb-4"
                                style={{fontFamily: "Georgia, serif"}}
                                dangerouslySetInnerHTML={{__html: quote.text}}
                            />

                            <p className="mb-3">— {quote.person}</p>
                            {quote.image && (
                                <img
                                    className="rounded-circle mb-5"
                                    width={150}
                                    src={quote.image}
                                    alt=""
                                />
                            )}
                            <div className="text-center">
                                <a
                                    className="btn btn-lg btn-primary h-3 ls-md"
                                    href="https://www.tomorrow.io/ebooks/aviation-ebook/"
                                >
                                    もっと読む
                                </a>
                            </div>
                        </div>

                    </section>
                )}

            </Layout>
        );
    }
}
